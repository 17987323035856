<template>
  <client-only>
    <Teleport v-if="opened" to="#awTeleportTargetModal">
      <focus-trap
        :active="isFocusTrapActive"
        :escape-deactivates="false"
        :click-outside-deactivates="false"
        :initial-focus="initialFocus"
        :fallback-focus="fallbackFocus"
      >
        <div
          ref="modalWrapper"
          :class="[$style.modalWrapper, {[$style.modalWrapperBlured]: backgroundBlured}, 'modalWrapper']"
          tabindex="-1"
          aria-modal="true"
          role="dialog"
          :aria-labelledby="idLabel"
          @click="handleClickWrapper"
        >
          <div :class="$style.modalScrollBox">
            <div :class="[$style.modal, $style[`size-${size}`]]">
              <button
                v-if="closable"
                :class="[$style.modalClose, {[$style.modalCloseWhiteRounded]: whiteRoundedCloseButton}]"
                type="button"
                :aria-label="$awt('aw.common.modal.close')"
                @click="close"
              >
                <lv-icon v-bind="closeIconAttrs" />
              </button>
              <div :class="$style.modalContent">
                <slot name="content" :class="$style.modalCloseIcon" />
              </div>
            </div>
          </div>
        </div>
      </focus-trap>
    </Teleport>
  </client-only>
</template>

<script>
  import { FocusTrap } from 'focus-trap-vue';
  import { nextTick } from 'vue';
  import ModalConfig from '../config/ModalConfig';
  import LvIcon from './LvIcon';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { uuid4 } from '~~/common/utils';

  export default {
    name: 'LvModal',
    components: {
      LvIcon,
      FocusTrap,
    },
    props: {
      opened: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: undefined,
      },
      closable: {
        type: Boolean,
        default: true,
      },
      idLabel: {
        type: String,
        default: null,
      },
      whiteRoundedCloseButton: {
        type: Boolean,
        default: false,
      },
      backgroundBlured: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['close-modal'],
    data () {
      const config = {
        ...ModalConfig,
        ...this.$appConfig.ModalConfig,
      };
      return {
        uuid: uuid4(),
        /* safariFocusTimeoutId retries with a delay because VO/iOS does
         * not change the screen reader focus. Tested with Safari13+iOS13
         */
        safariFocusTimeoutId: null,
        isFocusTrapActive: Boolean(this.opened),
        closeIconAttrs: config.closeIconAttrs,
      };
    },
    watch: {
      opened: {
        immediate: true,
        handler (newVal, oldVal) {
          const [bNewVal, bOldVal] = [Boolean(newVal), Boolean(oldVal)];
          if (bNewVal !== bOldVal) {
            useUserInterfaceStore().setBackdropChildren({
              uuid: this.uuid,
              value: bNewVal,
            });
            clearTimeout(this.safariFocusTimeoutId);
            if (!bNewVal) {
              this.isFocusTrapActive = bNewVal;
            }
            if (bNewVal) {
              nextTick(() => {
                if (import.meta.client) {
                  this.safariFocusTimeoutId = setTimeout(() => {
                    this.isFocusTrapActive = bNewVal;
                  }, 300);
                }
              });
            }
          }
          if (newVal && !oldVal) {
            window.addEventListener('keydown', this.handleEsc);
          } else if (!newVal && oldVal) {
            window.removeEventListener('keydown', this.handleEsc);
          }
        },
      },
    },
    beforeUnmount () {
      useUserInterfaceStore().setBackdropChildren({
        uuid: this.uuid,
        value: false,
      });
      clearTimeout(this.safariFocusTimeoutId);
    },
    methods: {
      handleClickWrapper (event) {
        if (event.target.classList.contains('modalWrapper')) {
          this.close();
        }
      },
      handleEsc (event) {
        if (event.key === 'Escape') {
          this.close();
        }
      },
      initialFocus () {
        return this.$refs.modalWrapper;
      },
      fallbackFocus () {
        return this.$refs.modalWrapper;
      },
      close () {
        if (this.closable) {
          this.$emit('close-modal');
        }
      },
    },

  };
</script>

<style module lang="scss" rel="stylesheet/scss">
$default-background-radius: (
  background: $color-white,
  border-radius: null
);
$lv-modal-background-radius-config: (
  xs: $default-background-radius,
  sm: $default-background-radius,
  md: $default-background-radius,
  lg: $default-background-radius,
  xl: $default-background-radius,
  full-screen: $default-background-radius,
) !default;

$lv-modal-box-shadow: 0 7px 17px 0 $color-black-10 !default;
$lv-modal-loader-background: $color-white-50 !default;
$lv-modal-min-height: 64px !default;
$lv-modal-wrapper-background: $color-black-50 !default;
$lv-modal-wrapper-full-screen-background: $color-white !default;

$lv-modal-close-padding: 16px !default;
$lv-modal-close-font-size: 16px !default;
$lv-modal-close-color: $color-brand-primary !default;
$lv-modal-close-background: transparent !default;
$lv-modal-close-hover-color: $color-brand-primary !default;
$lv-modal-close-active-color: $color-brand-primary !default;

$lv-modal-full-screen-box-shadow: none !default;
$lv-modal-full-screen-close-background: $color-black-075 !default;
$lv-modal-full-screen-close-hover-color: $color-brand-primary !default;
$lv-modal-full-screen-close-hover-background: $color-black-15 !default;
$lv-modal-full-screen-close-active-color: $color-brand-primary !default;

$lv-modal-valid-sizes: (xs, sm, md, lg, xl, full-screen) !default;

$lv-modal-widths: (
  xs: (
    xs: 400px,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  ),
  sm: (
    xs: 370px,
    sm: 500px,
    md: false,
    lg: false,
    xl: false,
  ),
  md: (
    xs: 545px,
    sm: false,
    md: 745px,
    lg: false,
    xl: false,
  ),
  lg: (
    xs: 370px,
    sm: 550px,
    md: 760px,
    lg: 1024px,
    xl: false,
  ),
  xl: (
    xs: 370px,
    sm: 550px,
    md: 860px,
    lg: 1350px,
    xl: false,
  ),
  full-screen: (
    xs: 100%,
    sm: 100%,
    md: 100%,
    lg: 100%,
    xl: 100%,
  ),
) !default;

$lv-modal-paddings: (
  xs: (
    xs: 30px 60px 50px 60px,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  ),
  sm: (
    xs: 30px 40px,
    sm: false,
    md: 50px,
    lg: false,
    xl: false,
  ),
  md: (
    xs: 25px 15px,
    sm: false,
    md: 30px 50px,
    lg: false,
    xl: false,
  ),
  lg: (
    xs: 20px 10px,
    sm: 10px,
    md: 20px,
    lg: 30px,
    xl: false,
  ),
  xl: (
    xs: 20px 10px,
    sm: 20px,
    md: 30px,
    lg: 40px,
    xl: false,
  ),
  full-screen: (
    xs: 0,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  ),
) !default;

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    @if map-has-key($map, $key) {
      $map: map-get($map, $key);
    } @else {
      @return null;
    }
  }
  @return $map;
}

.modal {
  position: relative;
  width: 100%;
  min-height: $lv-modal-min-height;
  margin: 8px;
  pointer-events: auto;
  box-shadow: $lv-modal-box-shadow;

  &Wrapper {
    position: fixed;
    z-index: 6001;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    overflow: auto;
    background: $lv-modal-wrapper-background;

    &Blured {
      background: rgba(16, 16, 16, 0.8);
    }
  }

  &ScrollBox {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    pointer-events: none;
  }

  &Close {
    font-size: $lv-modal-close-font-size;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    padding: $lv-modal-close-padding;
    cursor: pointer;
    color: $lv-modal-close-color;
    border: none;
    background: $lv-modal-close-background;

    &:hover,
    &:focus {
      color: $lv-modal-close-hover-color;
    }

    &:active {
      color: $lv-modal-close-active-color;
    }

    &Icon {
      display: block;
    }

    &WhiteRounded {
      top: 24px;
      right: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border: 1px solid $color-silver-chalice;
      border-radius: 999px;
      background: $color-white;
    }
  }

  @each $modal-size in $lv-modal-valid-sizes {
    $modal-width: map-get($lv-modal-widths, $modal-size);
    $modal-padding: map-get($lv-modal-paddings, $modal-size);

    &.size-#{$modal-size} {
      border-radius: map-deep-get($lv-modal-background-radius-config, $modal-size, border-radius);
      background: map-deep-get($lv-modal-background-radius-config, $modal-size, background);

      @each $res, $width in $modal-width {
        @if ($width) {
          @if ($res != xs) {
            @include media($res) {
              max-width: $width;
            }
          } @else {
            max-width: $width;
          }
        }
      }

      .modalContent {
        @each $res, $padding in $modal-padding {
          @if ($padding) {
            @if ($res != xs) {
              @include media($res) {
                padding: $padding;
              }
            } @else {
              padding: $padding;
            }
          }
        }

        border-radius: inherit;
      }
    }
  }

  &.size-full-screen {
    box-shadow: $lv-modal-full-screen-box-shadow;

    .modal {
      &Wrapper {
        background: $lv-modal-wrapper-full-screen-background;
      }

      &Close {
        position: fixed;
        top: 10px;
        right: 18px;
        transition: background $animation-speed-medium $animation-timing-function;
        border-radius: 100px;
        background: $lv-modal-full-screen-close-background;

        &:hover,
        &:focus {
          color: $lv-modal-full-screen-close-hover-color;
          background: $lv-modal-full-screen-close-hover-background;
        }

        &:active {
          color: $lv-modal-full-screen-close-active-color;
        }
      }
    }
  }
}
</style>
