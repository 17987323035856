export default {
  closeIconAttrs: {
    name: 'times',
    size: 15,
  },
  closeIconAttrsVersion2: {
    name: 'close-24',
    size: 10,
  },
  fullPageCloseIconAttrs: {
    name: 'arrow-left-16',
    size: 22,
  },
};
