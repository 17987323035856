<template>
  <client-only>
    <Teleport v-if="opened" to="#awTeleportTargetModal">
      <focus-trap
        :active="isFocusTrapActive"
        :escape-deactivates="false"
        :click-outside-deactivates="false"
        :initial-focus="initialFocus"
        :fallback-focus="fallbackFocus"
      >
        <div
          ref="modalWrapper"
          :class="[$style.modalWrapper, $style[styling]]"
          tabindex="-1"
          aria-modal="true"
          role="dialog"
          :aria-labelledby="idLabel"
        >
          <div :class="$style.modalHeader">
            <lv-button
              v-if="closable"
              :styling="closeButtonStyling"
              :class="$style.modalClose"
              type="button"
              :aria-label="$awt('aw.common.modal.close')"
              @click="close"
            >
              <span :class="[$style.modalCloseContainer, {[$style.modalCloseContainerOnlyIcon]: !closeLabel}]">
                <lv-icon v-bind="closeIconAttrs" />
                <span v-if="closeLabel" :class="$style.modalCloseLabel">
                  {{ closeLabel }}
                </span>
              </span>
            </lv-button>
            <lv-heading v-if="modalTitle" v-bind="headingAttrs" :class="$style.modalTitle">
              {{ modalTitle }}
            </lv-heading>
          </div>
          <div :class="$style.modal0">
            <div :class="$style.modal">
              <div :class="$style.modalContent">
                <slot name="content" :class="$style.modalCloseIcon" />
              </div>
            </div>
          </div>
        </div>
      </focus-trap>
    </Teleport>
    <slot name="contentInlined" />
  </client-only>
</template>

<script>
  import { FocusTrap } from 'focus-trap-vue';
  import { nextTick } from 'vue';
  import ModalConfig from '~~/common/components/loginet-vue-shop/config/ModalConfig';
  import { LvButton, LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { uuid4 } from '~~/common/utils';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import LvHeading from '~~/common/components/loginet-vue-shop/components/LvHeading.vue';

  export default {
    name: 'AwFullPageModal',
    components: {
      LvHeading,
      LvButton,
      LvIcon,
      FocusTrap,
    },
    props: {
      opened: {
        type: Boolean,
        default: false,
      },
      closable: {
        type: Boolean,
        default: true,
      },
      closeLabel: {
        type: String,
        default: () => this.$awt('aw.common.modal.fullpage.close'),
      },
      idLabel: {
        type: String,
        default: null,
      },
      styling: {
        type: String,
        default: 'default',
        validator: value => ['default', 'cart'].includes(value),
      },
      closeButtonStyling: {
        type: String,
        default: 'link',
      },
      closeIconAttrsOverwrite: {
        type: Object,
        default: () => ({}),
      },
      modalTitle: {
        type: String,
        default: '',
      },
      headingAttrs: {
        type: Object,
        default: () => ({
          level: '3',
          tag: 'h3',
          version2: true,
        }),
      },
    },
    emits: ['close-modal'],
    data () {
      const config = {
        ...ModalConfig,
        ...this.$appConfig.ModalConfig,
      };
      return {
        uuid: uuid4(),
        /* safariFocusTimeoutId retries with a delay because VO/iOS does
         * not change the screen reader focus. Tested with Safari13+iOS13
         */
        safariFocusTimeoutId: null,
        isFocusTrapActive: Boolean(this.opened),
        closeIconAttrs: { ...config.fullPageCloseIconAttrs, ...this.closeIconAttrsOverwrite },
      };
    },
    watch: {
      opened: {
        immediate: true,
        handler (newVal, oldVal) {
          const [bNewVal, bOldVal] = [Boolean(newVal), Boolean(oldVal)];
          if (bNewVal !== bOldVal) {
            useUserInterfaceStore().setBackdropChildren({
              uuid: this.uuid,
              value: bNewVal,
            });
            clearTimeout(this.safariFocusTimeoutId);
            if (!bNewVal) {
              this.isFocusTrapActive = bNewVal;
            }
            if (bNewVal) {
              nextTick(() => {
                if (import.meta.client) {
                  this.safariFocusTimeoutId = setTimeout(() => {
                    this.isFocusTrapActive = bNewVal;
                  }, 300);
                }
              });
            }
          }
          if (newVal && !oldVal) {
            window.addEventListener('keydown', this.handleEsc);
          } else if (!newVal && oldVal) {
            window.removeEventListener('keydown', this.handleEsc);
          }
        },
      },
    },
    beforeUnmount () {
      useUserInterfaceStore().setBackdropChildren({
        uuid: this.uuid,
        value: false,
      });
      clearTimeout(this.safariFocusTimeoutId);
    },
    methods: {
      initialFocus () {
        return this.$refs.modalWrapper;
      },
      fallbackFocus () {
        return this.$refs.modalWrapper;
      },
      close () {
        if (this.closable) {
          this.$emit('close-modal');
        }
      },
      handleEsc (event) {
        if (event.key === 'Escape') {
          this.close();
        }
      },
    },

  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.modal {
  position: relative;
  width: 100%;
  height: 100vh;
  pointer-events: auto;

  &Wrapper {
    font-family: $secondary-font;
    position: fixed;
    z-index: 6001;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    overflow: auto;
    color: $color-text-primary;

    &.default {
      background: $color-background-3;
    }

    &.cart {
      background-color: $color-background-1;
    }
  }

  &ScrollBox {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding: 20px 16px;
    pointer-events: none;
  }

  &Content {
    padding-right: 16px;
    padding-bottom: 20px;
    padding-left: 16px;
  }
}

.modalHeader {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 16px;
  gap: 16px;
  @include tablet(min) {
    margin-bottom: 20px;
  }

  .modalClose {
    width: auto;
    min-width: fit-content;

    &Container {
      display: grid;
      grid-template-columns: min-content auto;
      gap: 22px;
      vertical-align: middle;

      &OnlyIcon {
        grid-template-columns: 1fr;
        gap: 0;

      }
    }

    &Label {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .modalTitle {
    word-break: break-word;
    hyphens: auto;
  }
}
</style>
